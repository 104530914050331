import React, { useState, useEffect } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import { Flex, Text } from "@mantine/core";
import { confirmAlert } from "react-confirm-alert";

import { ErrorAlert, Loader } from "components";

import { checkTikkiePayStatus } from "core/methods";
import { ADD_PARTICIPANT_BODY, BOOKING_ID, TOTAL_PRICE } from "../../constants";

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
const humanReadablePaymentStatus = (status) => status.replace(/_/g, " ");

export default function TikkiePaymentVerification() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [paymentUrl, setPaymentUrl] = useState("");
  const [, setTotalPrice] = useState(0);
  const [isBookingComplete, setIsBookingComplete] = useState(false);
  const [loadingText, setLoadingText] = useState("Confirming your payment...");
  const [apiData, setApiData] = useState();

  const fetchPaymentStatus = async () => {
    const [isSuccess, data] = await checkTikkiePayStatus({
      paymentRequestToken: id,
    });

    if (!isSuccess) {
      confirmAlert({
        customUI: ({ onClose }) =>
          ErrorAlert({
            error: "An error occurred. Please try again",
            isServerError: true,
            onClose: () => {
              onClose();
              navigate(-1);
            },
          }),
      });
    }

    setPaymentUrl(data?.url);

    if (data.bookingPaymentStatus === "payment_succeeded") {
      setLoadingText("Adding your booking...");
      window.localStorage.removeItem(ADD_PARTICIPANT_BODY);
      window.localStorage.removeItem(BOOKING_ID);
      const totalAmount = window.localStorage.getItem(TOTAL_PRICE);
      setTotalPrice(Number(totalAmount));
      setIsBookingComplete(true);
      setApiData(data);
      setIsLoading(false);
    } else if (
      data.bookingPaymentStatus === "payment_failed" ||
      data.bookingPaymentStatus === "payment_canceled"
    ) {
      confirmAlert({
        customUI: ({ onClose }) =>
          ErrorAlert({
            error: `${capitalize(
              humanReadablePaymentStatus(data?.bookingPaymentStatus)
            )}. Please try again`,
            isServerError: false,
            onClose: () => {
              onClose();
              navigate(
                `/${data?.class_detail?.className}/${data?.class_detail?.referenceId}`
              );
              window.location.reload();
            },
          }),
      });
    } else {
      setTimeout(() => {
        fetchPaymentStatus(); // Recursive call to keep checking the status
      }, 5000);
    }
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await fetchPaymentStatus();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading) {
    return (
      <div className="ticket-view-loading">
        <Flex direction="column" gap={20} align="center" justify="center">
          <Loader size={40} />
          <Text align="center" fz="sm">
            {loadingText}
          </Text>
          {paymentUrl && (
            <>
              <Text fz="xs" fw={400} color="gray">
                Tikkie did not open?
              </Text>
              <Text
                fz="xs"
                fw={400}
                underline
                color="blue"
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href={paymentUrl}
              >
                Click here
              </Text>
            </>
          )}
        </Flex>
      </div>
    );
  } else if (apiData) {
    return (
      <Navigate
        to={`/order-confirmation/${apiData?.class_detail?.className
          .replace(/\s+/g, "-")
          .toLowerCase()}/${apiData?.class_detail?.referenceId}/${
          apiData?.bookingId
        }`}
        replace={true}
      />
    );
  }
}

export const ROUTE_NAMES = {
  APPLE_MERCHANT_ID:
    "/.well-known/apple-developer-merchantid-domain-association",
  LANDING: "/:name/:id",
  BOOKING: "/booking/:name/:id",
  SHOP: "/:businessName/webshop",
  CARD_PAYMENT: "/card-payment",
  IDEAL_PAYMENT: "/ideal-payment",
  SOCIAL_PAYMENT: "/social-payment",
  PAYMENT_CHECK: "/payment-check",
  PAYMENT_COMPLETE: "/payment-complete",
  TIKKIE_PAY_CHECK: "/tikkie-pay-check/:id",
  TIKKIE_PAYMENT: "/tikkie-payment",
  ORDER_CONFIRMATION: "/order-confirmation/:eventName/:refId/:bookingId",
  MANAGE_BOOKING: "/managebooking/event/:id",
  SCAN_TICKET: "/scan-ticket/:id",
  GET_TICKET: "/get-ticket/:ticketToken",
};
